import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AppUtils } from 'src/app/utils/app.utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public userCollapsed = false;
  public courseCollapsed = false;
  public eventCollapsed = false;
  public authorCollapsed = false;
  public categoryCollapsed = false;
  public newsCollapsed = false;

  user: any;
  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  ngOnInit() {
    const body = document.querySelector('body');
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
    this.accountService.getUserCacheInfo.then(result => { this.user = result });
  }
  

}
