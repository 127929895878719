import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, timer, interval } from 'rxjs';
import { environment } from '../../environments/environment';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {
  API_URL = environment.DOMAIN_API;

  login(user): Observable<any> {
    return this.httpClient.post(`${this.API_URL}/user/login`, user).pipe(map(result => {
      return result as any
    }));
  }

  register(user): Observable<any> {
    return this.httpClient.post(`${this.API_URL}/user/register`, user).pipe(map(result => {
      return result as any;
    }))
  }

  confirmRegister(user): Observable<any> {
    return this.httpClient.post(`${this.API_URL}/user/confirm-register`, user).pipe(map(result => {
      return result as any;
    }))
  }

  forgotPassword(user): Observable<any> {
    return this.httpClient.post(`${this.API_URL}/user/forgot-password`, user).pipe(map(result => {
      return result as any;
    }))
  }

  resetPassword(user): Observable<any> {
    return this.httpClient.post(`${this.API_URL}/user/reset-password`, user).pipe(map(result => {
      return result as any;
    }))
  }

  getMyInfo(): Observable<any> {
    return this.httpClient.get(`${this.API_URL}/user/me?` + this.requestToken).pipe(map(result => {
      return result as any;
    }))
  }

  userDetail(id): Observable<any> {
    return this.httpClient.get(`${this.API_URL}/user/detail/${id}?` + this.requestToken).pipe(map(result => {
      return result as any;
    }))
  }

  createUser(user): Observable<any> {
    return this.httpClient.post(`${this.API_URL}/user/create?` + this.requestToken, user).pipe(map(result => {
      return result as any;
    }))
  }

  editUser(user): Observable<any> {
    return this.httpClient.put(`${this.API_URL}/user/edit/${user.id}?` + this.requestToken, user).pipe(map(result => {
      return result as any;
    }))
  }

  getUserInfo(): Observable<any> {
    return this.httpClient.get(`${this.API_URL}/user/me?` + this.requestToken).pipe(map(result => {
      return result as any;
    }))
  } 

  allUser(): Observable<any> {
    return this.httpClient.get(`${this.API_URL}/user/all?` + this.requestToken).pipe(map(result => {
      return result as any;
    }))
  }

  processAgentRequest(id, answer): Observable<any> {
    return this.httpClient.get(`${this.API_URL}/user/process-agent-request/${id}/${answer}?` + this.requestToken).pipe(map(result => {
      return result as any;
    }))
  }

  updateStatus(data): Observable<any> {
    return this.httpClient.put(`${this.API_URL}/user/update-status?` + this.requestToken, data).pipe(map(result => {
      return result as any;
    }))
  }
  
  // cache request
  getUserCacheInfo = new Promise((resolve, reject) => {
    const source = interval(1000);
    const subscribe = source.subscribe(val => {
      let user = window.localStorage.getItem("USER");
      if (user != undefined) {
        resolve(JSON.parse(user));
        subscribe.unsubscribe();
      }
    })
  })
}
