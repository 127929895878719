import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortContent'
})
export class ShortContentPipe implements PipeTransform {

    transform(value: string, index: number): string {
        if (value.length> index) {
            let shortContent = `${value.substring(0, index)}....`;
            return shortContent;
        } else return value;


    }

}
