export class AppUtils {
  static domain = '';

  public static removeCookie() {
    var domain = window.location.host;
    document.cookie = '_tk_cec_' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;' + ";domain="+domain;
    document.cookie = '_tk_cec_' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;' + ";domain=localhost";

    window.localStorage.removeItem("TOKEN");
  }

  public static signOut() {
    this.removeCookie();
    setTimeout(() => {
      window.location.href = this.domain;
    }, 1000);
  }

  public static convertTimeUTC7(date) {
    date = new Date(date);
    var localDate = new Date(date.getTime());
    return localDate.toISOString().slice(0, 16);
  }

  public static saveCookie(token) {
    var domain = window.location.host;
    console.log(domain);
    document.cookie = "_tk_cec_=" + token + ";domain="+domain+";path=/";
    document.cookie = "_tk_cec_=" + token + ";domain=localhost;path=/";
  }
  
  public static getToken() {
    let token = this.getCookie('_tk_cec_');
    if (token == undefined || token == "") {
      return null;
    } else return token;
  }

  public static getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ')
        c = c.substring(1);
      if (c.indexOf(name) != -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  public static resizeImage(e, width, height) {
    var img = document.createElement("img");
    img.src = e.getAttribute("src");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
    var type = img.src.substring(img.src.indexOf(":") + 1, img.src.indexOf(";"))
    var dataSrc = ctx.canvas.toDataURL(type);
    return dataSrc;
  }

  public static isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  public static isValidEmail(email) {
    var emailRegex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    return emailRegex.test(email);
  }

  public static copyClipBoard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public static dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
