import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppUtils } from '../utils/app.utils';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(
    public httpClient: HttpClient,
    private route: ActivatedRoute
  ) { }

  get requestHeaders() {
    return this.createAPIHeader();
  }
  get requestToken() {
    return "CEC-ACCESS-KEY=" + AppUtils.getToken();
  }

  private createAPIHeader() {
    let token = AppUtils.getToken();
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
    });
  }

  convertTimeUTC7(date) {
    date = new Date(date);
    var localDate = new Date(date.getTime() + 25200000);
    return localDate.toISOString().slice(0, 16);
  }

  getParam(name) {
    return this.route.snapshot.queryParamMap.get(name);
  }

  isNumberOnly(input){
    return /^\d+$/.test(input);
  }
  
  resizeImage(e, width, height) {
    var img = document.createElement("img");
    img.src = e.getAttribute("src");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height);
    var type = img.src.substring(img.src.indexOf(":") + 1, img.src.indexOf(";"))
    var dataSrc = ctx.canvas.toDataURL(type);
    return dataSrc;
  }

  isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  };

  isValidEmail(email) {
    var emailRegex = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
    return emailRegex.test(email);
  }

  copyClipBoard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
