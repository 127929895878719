import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AccountService } from './services/account.service';

import { AppUtils } from './utils/app.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'demo1';

  showSidebar: boolean = true;
  showNavbar: boolean = true;
  showFooter: boolean = true;
  isLoading: boolean;
  prize: any;
  user: any;

  private feedSubscription: Subscription;
  @ViewChild('winPrizeModal', { static: true }) private winPrizeModal: TemplateRef<any>;  

  constructor(
    private router: Router,
    private accountService: AccountService, 
    private modalService : NgbModal
  ) {
    // Removing Sidebar, Navbar, Footer for Documentation, Error and Auth pages
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'].indexOf("/user-pages/") > -1 || event['url'].indexOf("/error-pages/") > -1) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
          document.querySelector('.main-panel').classList.add('w-100');
          document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg',);
          document.querySelector('.content-wrapper').classList.remove('auth', 'lock-full-bg');
          if ((event['url'] == '/error-pages/404') || (event['url'] == '/error-pages/500')) {
            document.querySelector('.content-wrapper').classList.add('p-0');
          }
        } else {
          this.showSidebar = true;
          this.showNavbar = true;
          this.showFooter = true;
          document.querySelector('.main-panel').classList.remove('w-100');
          document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
          document.querySelector('.content-wrapper').classList.remove('auth', 'auth-img-bg');
          document.querySelector('.content-wrapper').classList.remove('p-0');
        }
      }
    });

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });    
  }
  ngOnInit() {
    // Scroll to top after route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.getUserInfo();

  }
  getUserInfo() {
    let token = AppUtils.getToken();
    console.log(token);

    if (token == null) {
      this.router.navigate(['/user-pages/login']);
    } else {
      this.accountService.getUserInfo().subscribe(result => {
        console.log(result);
        this.user = result;
        window.localStorage.setItem("USER", JSON.stringify(this.user));       
      }, err => {
        console.log(err);
        this.router.navigate(['/user-pages/login']);
      })
    }
  }

  ngOnDestroy() {
    this.feedSubscription.unsubscribe();
  }
}
